import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './modules/shared/services/auth-guard/auth-guard.service';

import {PageNotFoundComponent} from './modules/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [

  {
    path: '',
    loadChildren: () =>
      import(`./modules/base/base.module`).then((m) => m.BaseModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
