import {ComponentFactoryResolver, Inject, Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import * as SharedActions from '../../core/shared.actions';
import * as fromRoot from '../../../../state/app.state';
import {Store} from '@ngrx/store';
import {BasicDialogComponent} from '../../components/dialogbox/basic-dialog/basic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  isLoadingScreenOpen = new BehaviorSubject(false);

  // Used for copyToClipboard(value)
  private dom: Document;

  constructor(
    private store: Store<fromRoot.State>,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) dom: Document,
    private resolver: ComponentFactoryResolver
  ) {

    // Used for copyToClipboard(value)
    this.dom = dom;
  }

  setToLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  getFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  setToSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  getFromSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
  }

  setAuthenticationToken(token) {
    this.setToLocalStorage('token', token);
  }

  getAuthenticationToken() {
    return this.getFromLocalStorage('token');
  }

  isAuthenticated() {
    return !!this.getAuthenticationToken();
  }

  logout() {

  }


  clearAppStorage() {
    const appStorageKeys = ['authentication'];

    appStorageKeys.forEach((key, index) => {
      this.removeFromLocalStorage(key);
    });
  }

  callAPI(type, url, payload?, options = {}) {
    console.log(type, url, payload)
    return this.http[type]<any>(url, payload, {observe: 'response', ...options})
      .pipe(
        map((response: HttpResponse<any>) => {
          console.log(response)
          return response.body || response
        }),
        catchError((error) => {
          console.log(error)
          return throwError(error)
        })
      );
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  openInCurrentTab(url) {
    window.open(url, '_self');
  }

  startLoading() {
    this.store.dispatch(SharedActions.StartLoading());
  }

  stopLoading() {
    this.store.dispatch(SharedActions.StopLoading());
  }

  openDialog(config = {}) {
    const dialogConfig = new MatDialogConfig();
    this.dialog.open(BasicDialogComponent, {...dialogConfig, ...config, disableClose: true});
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkFormValidation(form, errorMessageMap, currentField?) {
    const errorMessages = {};
    const formControls = form.controls || null;

    if (currentField) {
      const errors = form.controls[currentField].errors;
      const firstErrorType = errors ? Object.keys(errors)[0] : null;
      errorMessages[currentField] = errorMessageMap[currentField][firstErrorType];
      return errorMessages;
    }

    for (const eachControlName in formControls) {
      const isControlValid = form.controls[eachControlName].valid;
      if (!isControlValid) {
        const errors = form.controls[eachControlName].errors;
        const firstErrorType = errors ? Object.keys(errors)[0] : null;
        if (firstErrorType) {
          form.controls[eachControlName].markAsTouched();
          errorMessages[eachControlName] = errorMessageMap[eachControlName][firstErrorType];
        }
      }
    }
    return errorMessages;
  }

  notification(message, type?, actionText?, notificationDuration?) {
    let duration = 2000;
    if (notificationDuration) {
      duration = notificationDuration;
    }
    this.snackBar.open(message, actionText, {
      duration,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: [type],
    });
  }

  uploadToBlobStorage(payload, allowDownload = true): Observable<any> {
    const req = new HttpRequest('PUT', payload.url, payload.data, {
      reportProgress: true,
      responseType: 'json',
    });

    let headers = req.headers;
    headers = headers.append('x-ms-blob-type', 'BlockBlob');
    headers = headers.append('file-upload', 'true');
    headers = headers.append('Content-Type', payload.fileType);
    if (allowDownload) {
      headers = headers.append('x-ms-blob-content-disposition', 'attachment');
    }
    const requestToSend = req.clone({headers});
    return this.http.request(requestToSend);
  }

  deleteFromBlobStorage(url): Observable<any> {
    const req = new HttpRequest('DELETE', url, {
      reportProgress: true,
      responseType: 'json',
    });

    let headers = req.headers;
    headers = headers.append('x-ms-blob-type', 'BlockBlob');
    headers = headers.append('file-upload', 'true');
    const requestToSend = req.clone({headers});
    return this.http.request(requestToSend);
  }
}
