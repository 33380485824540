import {AfterContentChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../services/common/common.service';
import {delay, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as SharedSelectors from '../../core/shared.selectors';
import * as fromRoot from '../../../../state/app.state';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit, OnDestroy, AfterContentChecked {
  isLoadingSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<LoadingScreenComponent>,
    private commonService: CommonService,
    private store: Store<fromRoot.State>
  ) {
    this.isLoadingSubscription = this.store.pipe(select(SharedSelectors.isLoading))
      .pipe(delay(0))
      .subscribe((data) => {
        if (!data.length) {
          this.dialogRef.close();
          this.commonService.isLoadingScreenOpen.next(false);
        }
      });
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {

  }

  ngOnDestroy() {
    this.isLoadingSubscription?.unsubscribe();
  }
}
