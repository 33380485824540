import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import {MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import { CKEditorModule } from 'ckeditor4-angular';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

// Modules
import {MaterialModule} from './material-module';


// Components
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {TextInputComponent} from './components/form-fields/input/text-input/text-input.component';
import{NumberInputComponent} from './components/form-fields/input/number-input/number-input.component';
import { BasicDialogComponent } from './components/dialogbox/basic-dialog/basic-dialog.component';
import {LoadingScreenComponent} from './components/loading-screen/loading-screen.component';
import {SharedEffects} from './core/shared.effects';
import {reducer} from './core/shared.reducer';

import {PrimaryButtonComponent} from './components/buttons/primary-button/primary-button.component';
import {PrimaryClickableTextComponent} from './components/buttons/primary-clickable-text/primary-clickable-text.component';
import {SecondaryButtonComponent} from './components/buttons/secondary-button/secondary-button.component';

import {LabelTypeOneComponent} from './components/labels/label-type-one/label-type-one.component';
import {LabelTypeTwoComponent} from './components/labels/label-type-two/label-type-two.component';
import {LabelTypeThreeComponent} from './components/labels/label-type-three/label-type-three.component';
import {LabelTypeFourComponent} from './components/labels/label-type-four/label-type-four.component';

// Pipes
import {DateFormatPipe} from './pipes/date-format.pipe';
import {SafeHTMLPipe} from './pipes/safe-html.pipe';
import {PhoneNumberFormatPipe} from './pipes/phone-number-format.pipe';

// Directives
import {DateDirective} from './directives/date.directive';
import {OnlyDigitsDirective} from './directives/only-digits.directive';
import {PhoneNumberDirective} from './directives/phone-number.directive';
import {PreventMultipleClickDirective} from './directives/prevent-multiple-click.directive';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {SingleSelectComponent} from './components/form-fields/dropdown/single-select/single-select.component';
import { IconComponent } from './components/icon/icon.component';




@NgModule({
  declarations: [
    OnlyDigitsDirective,
    DateDirective,
    DateFormatPipe,
    SafeHTMLPipe,
    OnlyDigitsDirective,
    PhoneNumberDirective,
    PreventMultipleClickDirective,
    PageNotFoundComponent,
    LoadingScreenComponent,
    BasicDialogComponent,
    TextInputComponent,
    NumberInputComponent,
    PrimaryButtonComponent,
    PrimaryClickableTextComponent,
    SecondaryButtonComponent,
    LabelTypeOneComponent,
    LabelTypeTwoComponent,
    LabelTypeThreeComponent,
    LabelTypeFourComponent,
    SafeUrlPipe,
    SingleSelectComponent,
    IconComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    FlexModule,
    StoreModule.forFeature('shared', reducer),
    EffectsModule.forFeature(
      [SharedEffects]
    ),
    CKEditorModule,
    FlexLayoutModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    SafeHTMLPipe,
    TextInputComponent,
    NumberInputComponent,
    PrimaryButtonComponent,
    PrimaryClickableTextComponent,
    SecondaryButtonComponent,
    LabelTypeOneComponent,
    LabelTypeTwoComponent,
    LabelTypeThreeComponent,
    LabelTypeFourComponent,
    SafeUrlPipe,
    SingleSelectComponent,
    IconComponent
  ],
  providers: [
    DatePipe,
    DateFormatPipe,
    PhoneNumberFormatPipe
  ]
})
export class SharedModule {
}
