import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {CommonService} from './modules/shared/services/common/common.service';
import * as fromRoot from './state/app.state';
import * as SharedSelectors from '../app/modules/shared/core/shared.selectors';
import {Subscription} from 'rxjs';
import {LoadingScreenComponent} from './modules/shared/components/loading-screen/loading-screen.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'pageBuilder';

  isLoadingSubscription: Subscription;
  breakPointObserverSubscription: Subscription;
  isLoadingScreenOpenSubscription: Subscription;

  isAppDisabled = false;
  isLoadingScreenOpen = false;

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    public breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    this.isLoadingScreenOpenSubscription = this.commonService.isLoadingScreenOpen.subscribe(data => {
      this.isLoadingScreenOpen = data;
    })

    this.isLoadingSubscription = this.store.pipe(select(SharedSelectors.isLoading)).subscribe(data => {
      if (!!data.length && !this.isLoadingScreenOpen) {
        this.commonService.isLoadingScreenOpen.next(true);
        this.commonService.openDialog({
          data: {
            component: LoadingScreenComponent,
          },
          backdropClass: 'spinner-backdrop',
          panelClass: 'spinner-panel'
        });
      }
    });

    this.breakPointObserverSubscription = this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        this.isAppDisabled = !state.matches;
      });
  }

  ngOnDestroy() {
    this.isLoadingSubscription?.unsubscribe();
    this.breakPointObserverSubscription?.unsubscribe();
    this.isLoadingScreenOpenSubscription?.unsubscribe();
  }
}
